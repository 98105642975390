/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #082257;
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.cpattxt{
  margin-top: 80px;
  font-size: 120px;
  margin-left: -26%;
  font-family: "Poppins";
}
.cfp{
  margin-top: -240px;
  font-size: 30px;
  font-family: "Poppins";
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.clogo{
  position: relative;
  height: 130px;
  top: -255px;
  left: 32%;

}
@media (max-width:480px)and (min-width:320px){
  .cpattxt{
    font-size: 80px;
    margin-top: -200px;
  }
  .clogo{
    height: 90px;
    position: relative;
    top: -170px;
    left: 32%;
  }
  .cfp{
    margin-top: -160px;
    font-size: 20px;
    font-family: "Poppins";
  }
} */
select:invalid,
    select option[value=""] {
      color: rgba(51, 51, 51, 0.6)
    }

    .button.with-icon:hover .button-icon {
      fill: #fff !important;
    }

    .w-embed table {
      min-width: 400px;
      margin-top: 10px;
      margin-bottom: 25px;
      border-radius: 10px;
      overflow: hidden;
    }

    .w-embed table thead tr:nth-child(1) th {
      background-color: #f2f2f2 !important;
      font-weight: 700;
      color: #494a4d;
    }


    .w-embed td,
    .w-embed th {
      text-align: center;
      padding: 10px;
      background: white;
      border: 1px solid lightgrey;
    }

    @media(max-width: 568px) {
      .cities-wrapper {
        max-height: 100px;
        overflow-y: scroll;
      }
    }

    .formula-section {
      box-shadow: none !important;
      background-color: transparent !important;
    }

    .back-to-top-button a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
    .button.with-icon:hover .button-icon {
      fill: white;
    }

    @media(max-width: 450px) {
      .w-embed table {
        min-width: 100%;
      }
    }